<template>
  <div class="divBox">
    <el-card class="mt14">
      <el-table :data="tableData.data" size="small" highlight-current-row>
         <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100"
          label="序号"
        />

        <!-- <el-table-column prop="id" label="ID" min-width="120" /> -->
        <el-table-column
          prop="serviceNickname"
          label="客服名称"
          min-width="120"
        />
        <el-table-column label="头像" min-width="150">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.serviceAvatarUrl"
                :preview-src-list="[scope.row.serviceAvatarUrl]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="添加时间" min-width="120" />
        <el-table-column label="详情" min-width="170" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-setting"
              size="small"
              @click="toChat"
              >进入工作台</el-button
            >
            <el-button
              type="text"
              icon="el-icon-document"
              size="small"
              @click="chatRecord(scope.row.id)"
              >聊天记录</el-button
            >
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              @click="onEdit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="客服头像:" prop="image">
          <FormImgUpload
            :url="form.image"
            @upload="upload"
            :limit="1"
            :maxSize="1"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
        </el-form-item>

        <el-form-item label="昵称" prop="serviceNickname">
          <el-input
            class="inputWidth"
            placeholder="请输入客服昵称"
            v-model="form.serviceNickname"
            maxlength="30"
            size="mini"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="账号" prop="serviceUsername">
          <el-input
            class="inputWidth"
            placeholder="请输入客服账号"
            v-model="form.serviceUsername"
            maxlength="30"
            size="mini"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="密码" prop="servicePassword">
          <el-input
            class="inputWidth"
            placeholder="请输入密码"
            v-model="form.servicePassword"
            maxlength="30"
            size="mini"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="servicePasswordConfirm">
          <el-input
            class="inputWidth"
            placeholder="请输入密码"
            v-model="form.servicePasswordConfirm"
            maxlength="30"
            size="mini"
          >
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            :loading="vLoading"
            @click="submitForm('form')"
            >提交</el-button
          >
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="聊天记录"
      :visible.sync="dialogVisibleTable"
      width="700px"
    >
      <div class="tipsBox">系统聊天记录只保留最近三个月，过期将被清除</div>
      <ChatTable v-if="dialogVisibleTable" />
    </el-dialog>
  </div>
</template>
<script>
// import {
//   expressCompanyList,
//   expressCompanyUpdate
// } from "@/api/system/config";
import { serviceList, serviceUp } from "@/api/service/service";
import { getIndex } from "@/utils/other";
import { enterNumberEnglish } from "@/utils/formRules";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import ChatTable from "./chatTable";
export default {
  components: {
    FormImgUpload,
    ChatTable,
  },
  name: "manage",
  data() {
    return {
      dialogVisibleTable: false,
      vLoading: false,
      listLoading: false,
      title: "新增",
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
      },
      storeList: {},
      form: {
        image: [],
        sellPrice: "",
        receivePrice: "",
        sort: "",
      },
      tableData: {
        data: [{}],
        total: 0,
      },
      dialogVisible: false,
      dialogVisibleLink: false,
      rules: {
        image: [{ required: true, message: "请上传头像", trigger: "blur" }],
        serviceNickname: [
          { required: true, message: "请输入昵称", trigger: "blur" },
        ],
        serviceUsername: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        servicePassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          ...enterNumberEnglish
        ],
        servicePasswordConfirm: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          ...enterNumberEnglish
        ],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },
    uploadDelete() {},
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadAfter(res, done) {
      done();
    },
    upload(e) {
      console.log("123", e);
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    toChat() {
      if (window.location.hostname.includes("supplier")) {
        window.open('https://qygbnsupplier.bono.com.cn/#/', '_blank'); 
      } else {
        window.open('https://qygbnstore.bono.com.cn/#/', '_blank'); 
      }
    },
    getList() {
      this.listLoading = true;
      serviceList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    storeChange() {
      this.form.aboutId = "";
      this.form.aboutName = "";
    },
    getGoodsItem(row) {
      this.form.aboutId = row.id;
      this.form.aboutName = row.name;
      this.dialogVisibleLink = false;
    },
    formSubmit() {},
    add() {
      this.title = "新增";
      this.dialogVisible = true;
    },
    //历史记录
    chatRecord() {
      this.dialogVisibleTable = true;
    },
    // 编辑
    onEdit(row) {
      this.title = "编辑";
      row.image = [row.serviceAvatarUrl];
      this.form = JSON.parse(JSON.stringify(row));
      console.log("123", this.form);
      this.dialogVisible = true;
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.form.title = "";
      this.form.image = [];
      this.form.status = 0;
      this.dialogVisible = false;
    },
    handleCloseLink() {  
      this.dialogVisibleLink = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            if(this.form.servicePassword != this.form.servicePasswordConfirm){
              this.$message.error("密码不一致");
              return
            }
            this.vLoading = true;
            serviceUp({
              ...this.form,
              serviceAvatarUrl: this.form.image[0],
            }).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                this.$message.success("修改成功");
                this.vLoading = false;
                this.getList();
                this.handleClose();
                // done();
              } else {
                this.vLoading = false;
                this.$message.error(res.msg);
                // done();
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.changeLink {
  width: 200px;
  height: 30px;
  border: 1px solid #dcdfe6;
  line-height: 30px;
  font-size: 14px;
  padding-left: 14px;
  box-sizing: border-box;
  cursor: pointer;
}
.inputWidth {
  width: 300px;
}
.inputBox {
  width: 100%;
  display: flex;
  align-items: center;
}
.tipsBox {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bcbec2;
  background-color: #f4f4f5;
  border-color: #e9e9eb;
  font-size: 14px;
  margin-bottom: 10px;
}
</style>
  